<template>
  <footer class="pdf-footer">
    Zanzar Mídia LTDA - CNPJ 30.258.165-0001/29 - contato@zanzar.com.br
  </footer>
</template>

<script>
  export default {
    name: 'FooterPDF',
  }
</script>
<style scoped>
.pdf-footer {
  position: fixed;
  bottom: 40px;
  color: #707780;
  width: 100%;
  text-align: center;
}
</style>
import { createStore } from 'vuex';
import axios from 'axios';
import downloadHelper from '../helpers/download.helper';
import { booleanify } from '../helpers/type.helper';

const loginModule = {
  namespaced: true,
  state: () => ({
    token: '',
  }),
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      if (payload) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload;
      }
    }
  },
  actions: {
    login: ({ commit }, payload) => {
      return axios
        .post('sessions', payload)
        .then((response) => {
          commit('setToken', payload.token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
          sessionStorage.setItem('token', response.data.token);
          return response.data;
        });
    }
  },
  getters: {}
}

const pdf = {
  namespaced: true,
  state: () => ({
    isPdf: false,
    exibirCapa: true,
    exibirPaginaInicial: true,
    exibirGraficoInsercoes: true,
    exibirGraficoImpactos: true,
    exibirPaginaFinal: true,
    agency: null,
    pi: null,
    filter: {},
  }),
  mutations: {
    setFilter(state, payload) {
      if (payload) {
        state.filter = JSON.parse(decodeURI(payload));
        state.isPdf = true;
        state.exibirCapa = booleanify(state.filter.exibir_capa);
        state.exibirPaginaInicial = booleanify(state.filter.exibir_pagina_inicial);
        state.exibirGraficoInsercoes = booleanify(state.filter.exibir_grafico_insercoes);
        state.exibirGraficoImpactos = booleanify(state.filter.exibir_grafico_impactos);
        state.exibirPaginaFinal = booleanify(state.filter.exibir_pagina_final);
        state.agency = state.filter.agency;
        state.pi = state.filter.pi;
      }
    }
  },
  actions: {},
  getters: {}
}

const dashBoard = {
  namespaced: true,
  state: () => ({
    cpm: '',
    dashboard: {},
    dashboard_products: [],
    neighbordhoods: [],
    passengers:'',
    insertions: {},
    impacts: {},
    clientCampaigns: [],
  }),
  mutations: {
    reset(state) {
      state.cpm = '';
      state.dashboard = {};
      state.neighbordhoods = [];
    },
    setClientCampaigns(state, payload) {
      state.clientCampaigns = payload;
    },
    setCpm(state, payload) {
      state.cpm = payload;
    },
    setNeighbordhoods(state, payload) {
      state.neighbordhoods = payload;
    },
    setDashboard(state, payload) {
      state.dashboard = payload?.full_data;
      state.dashboard_products = payload?.products;
    },
    setPassengers(state, payload) {
      state.passengers = payload;
    },
    setInsertions(state, payload) {
      state.insertions = payload;
    },
    setImpacts(state, payload) {
      state.impacts = payload;
    },
  },
  actions: {
    getInsertions: ({ commit }, payload) => {
      return axios
        .post('graphs/insertions', payload)
        .then((response) => {
          commit('setInsertions', response.data);
          return response.data;
        });
    },
    getImpacts: ({ commit }, payload) => {
      return axios
        .post('graphs/impacts', payload)
        .then((response) => {
          commit('setImpacts', response.data);
          return response.data;
        });
    },
    cpm: ({ commit }, payload) => {
      return axios
        .post('dashboard/cpm', payload)
        .then((response) => {
          commit('setCpm', response.data);
          return response.data;
        });
    },
    getCampaigs: ({ commit }, payload) => {
      return axios
        .get('campaigns?'+ 'fileType='+payload.fileType+'&clientId='+ payload.clientId)
        .then((response) => {
          commit('setCpm', response.data.cpm);
          return response.data;
        });
    },
    getClient: ({ commit }) => {
      return axios
        .get('clients')
        .then((response) => {
          commit('setCpm', response.data.cpm);
          return response.data;
        });
    },
    getClientCampaigns: ({ commit }, payload) => {
      return axios
        .get(`clients/${payload}/campaigns`)
        .then((response) => {
          commit('setClientCampaigns', response.data);
          return response.data;
        });
    },
    neighbordhoods: ({ commit }, payload) => {
      return axios
        .post('dashboard/top-neighborhoods', payload)
        .then((response) => {
          commit('setNeighbordhoods', response.data);
          return response.data;
        });
    },
    passengers: ({ commit }, payload) => {
      return axios
        .post('dashboard/passengers', payload)
        .then((response) => {
          commit('setPassengers', response.data.totalPassengers);
          return response.data;
        });
    },
    dashBoard: ({ commit }, payload) => {
      return axios
        .post('dashboard', payload)
        .then((response) => {
          commit('setDashboard', response.data);
          return response.data;
        });
    },
    // eslint-disable-next-line no-unused-vars
    getPdf: ({ commit }, payload) => {
      return axios
        .post(
          'dashboard/get-pdf',
          payload,
          {
            headers: {
              'content-type': 'multipart/form-data; boundary=--------------------------875960327702482286453567',
            },
            responseType: 'blob',
          },
        )
        .then((response) => {
          downloadHelper.download(response.data, `report-${Date.now()}.pdf`, 'application/pdf');
        });
    },
    // eslint-disable-next-line no-unused-vars
    getPdfFromUrl: ({ commit }, payload) => {
      return axios
        .post(
          'dashboard/get-pdf-url',
          payload,
          {
            headers: {
              'content-type': 'multipart/form-data; boundary=--------------------------875960327702482286453567',
            },
          },
        )
        .then((response) => {
          downloadHelper.downloadFromUrl(response.data.file_url, `report-${Date.now()}.pdf`);
        });
    },
    // eslint-disable-next-line no-unused-vars
    sendPdfFromUrl: ({ commit }, payload) => {
      return axios
        .post(
          'dashboard/get-pdf-url',
          payload,
          {
            headers: {
              'content-type': 'multipart/form-data; boundary=--------------------------875960327702482286453567',
            },
          },
        );
    },
  },
  getters: {}
}

export default createStore({
  modules: {
    login: loginModule,
    dashBoard,
    pdf,
  }
});

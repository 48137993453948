import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import VueApexCharts from "vue3-apexcharts";
import 'vue-universal-modal/dist/index.css';

import VueUniversalModal from 'vue-universal-modal';

axios.defaults.baseURL = window.zanzar_dashboard_api_url;

loadFonts();

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      sessionStorage.removeItem('token');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueApexCharts)
  .use(VueUniversalModal, {
    teleportTarget: '#modals',
  })
  .mount('#app');

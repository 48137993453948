<template>
  <div>
    <footer-pdf v-show="isPdf && exibirPaginaInicial" style="text-align: center; padding-top: 10px;" />

    <div
      v-if="isPdf && exibirCapa"
      :style="[
        pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
        pdfMaxHeight ? { 'height': `${pdfMaxHeight}px` } : {},
      ]"
      class="pdf-content"
      >
      <header-pdf title="Relatório de Campanha" />
      <img
        v-if="pdfLoaded"
        :src="imagemCapa"
        :style="[
          pdfMaxWidth ? { 'max-width': '1240px' } : {},
          pdfMaxHeight ? { 'max-height': '960px' } : {},
        ]"
      />
    </div>
    <p class="break" v-if="isPdf && exibirCapa && (exibirPaginaInicial || exibirGraficoInsercoes || exibirGraficoImpactos || exibirPaginaFinal)"></p>

    <div
      v-if="isPdf && exibirPaginaInicial"
      :style="[
        pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
        pdfMaxHeight ? { 'height': `0px` } : {},
      ]"
      class="pdf-content"
    >
      <header-pdf title="Relatório de Campanha" />
    </div>

    <div v-if="isPdf && exibirPaginaInicial" style="margin-left: 75px;">
      <v-row style="margin-top: 80px">
        <v-col cols="6" style="padding-top: 60px; font-size: 26px; white-space: nowrap;">
          <label class="text-label">Cliente: </label> {{ pdfData.cliente }}
          <br/>
          <span v-if="pdfData.agencia">
            <label class="text-label">Agência: </label> {{ pdfData.agencia }}
            <br/>
          </span>
          <label class="text-label">Período: </label> {{ pdfData.periodo }}
          <br/>
          <label class="text-label">Número da PI: </label> {{ pdfData.pi }}
          <br/>
          <label class="text-label">Produto: </label> {{ pdfData.produto }}
          <br/>
        </v-col>
        <v-col cols="6" style="padding-left: 345px; padding-top: 46px;">
          <img
            height="216"
            width="160"
            :src="require('@/assets/avatar-zanzar.png')"
          />
        </v-col>
      </v-row>
    </div>

    <v-container v-show="!isPdf">
      <v-row>
        <v-card class="card-filtros mt-5 mb-5">
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="4" xs="6" sm="4" md="4">
                  <label for="cliente">Cliente *</label>
                  <v-autocomplete
                    v-model="cliente"
                    :items="clienteList"
                    class="custom-select"
                    dense
                    filled
                    @update:modelValue="getClientCampaigns(cliente.id)"
                    item-title="name"
                    item-value="id"
                    return-object
                    variant="outlined"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" xs="6" sm="4" md="4">
                  <label for="agencia">Agência</label>
                  <Multiselect
                    mode="tags"
                    v-model="agencia"
                    :searchable="true"
                    :close-on-select="true"
                    :options="agenciaList"
                    @update:modelValue="setOsList()"
                  />
                </v-col>

                <v-col cols="4" xs="6" sm="4" md="4">
                  <label for="ordem">Ordem serviço</label>
                  <Multiselect
                    mode="tags"
                    v-model="os"
                    :searchable="true"
                    :close-on-select="true"
                    :options="osList"
                    @update:modelValue="setProductList()"
                  />
                </v-col>

                <v-col cols="4" xs="6" sm="4" md="4">
                  <label for="produto">Produto(s) *</label>
                  <Multiselect
                    mode="tags"
                    v-model="produto"
                    :searchable="true"
                    :close-on-select="false"
                    :options="produtoList"
                    @update:modelValue="selectProduct()"
                  />
                </v-col>

                <v-col cols="4" xs="6" sm="4" md="4">
                  <label for="cidade">Praça(s)</label>
                  <Multiselect
                    mode="tags"
                    v-model="cidade"
                    :searchable="true"
                    :close-on-select="false"
                    :options="cidadeList"
                    @update:modelValue="setProductList()"
                  />
                  <template v-slot:multipleLabel="{ cidade }">
                    <div class="multiselect-multiple-label">
                      {{ cidade.length }}
                    </div>
                  </template>
                </v-col>

                <v-col class="col-periodo" cols="4" xs="6" sm="4" md="4">
                  <label for="periodo">Período *</label>
                  <date-range-picker
                    :ranges="false"
                    name="periodo"
                    class="input-custom date-pick"
                    id="data"
                    ref="picker"
                    :dateRange="dateRange"
                    :max-date="new Date()"
                    v-model="dateRange"
                    :locale-data="localeData"
                    :autoApply="true"
                    @update:modelValue="setDateRange"
                  >
                  </date-range-picker>
                  <small v-if="rangeDate.startDate">Período disponível: {{ rangeDate.startDate }} a {{ rangeDate.endDate }}</small>
                </v-col>

                <v-col cols="4">
                  <label for="relatorioParcial">Relatório parcial: &nbsp;&nbsp;</label> <Toggle v-model="relatorioParcial" id="relatorioParcial" />
                </v-col>

                <v-col cols="4">
                  <label for="exibirPorProduto">Exibir por produto: &nbsp;&nbsp;</label> <Toggle v-model="exibirPorProduto" id="exibirPorProduto" />
                </v-col>

                <v-col cols="12">
                  <label for="investimento">Valor: &nbsp;&nbsp;</label> {{ investimento }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center" cols="12" xs="12" sm="12" md="4">
                <!-- :disabled="!validation" -->
                <button class="btn-filtrar" @click="filtrar()">Visualizar</button>
                &nbsp;
                <button 
                  class="btn btn-success"
                  :disabled="!validation"
                  @click="showModalEnviarRelatorio()"
                >Gerar relatório</button>
            </v-col>
          </v-row>
        </v-card>

        <!-- <v-col cols="12" xs="12" sm="12" md="2">
          <label for="corridas">Número de corridas</label>
          <input  class="input-real px-3" v-model="corridas" >
        </v-col> -->
      </v-row>
    </v-container>

    <v-snackbar v-model="erroCampanha" timeout="60000" multi-line>
      {{ erroMessage || 'Ocorreu um erro no processamento. Por favor, tente novamente.' }}
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="erroCampanha = false"
        >
          FECHAR
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="erroValidation" timeout="60000" multi-line>
      Preencha todos os campos obrigatórios para prosseguir!
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="erroValidation = false"
        >
          FECHAR
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="loading || loadingFilter" class="text-center my-5">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <v-row
      v-if="showDashboard && showPassengers && exibirPaginaInicial"
      class="first-row-dash bg-white mb-5 fontNunitoSans"
    >
      <v-col>
        <v-container>
          <v-row class="color-707780 justify-space-between">
            <v-col :cols="isPdf ? 6 : 12" xs="6" md="6" v-if="showDashboard">
              <div class="center description-block">
                <span class="title-value font-36">{{ dashboardDetail.runs }}</span><br>
                <span class="font-18">Corridas</span>
              </div>
            </v-col>
            <v-col :cols="isPdf ? 6 : 12" xs="6" md="6" v-if="showPassengers">
              <div class="center description-block">
                <span class="title-value font-36">{{ passengers }}</span><br>
                <span class="font-18">Passageiros</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row
      v-if="showDashboard && exibirPaginaInicial"
      class="first-row-dash bg-white mb-5 fontNunitoSans"
    >
      <v-col>
        <v-row v-for="(dashboardItem, index) in dashboardList" :key="index">
          <p class="break" v-if="isPdf && [2,6,10,14,18,22].includes(index)"></p>
          <div
            v-if="isPdf && [2,6,10,14,18,22].includes(index)"
            :style="[
              pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
              pdfMaxHeight ? { 'height': `0px` } : {},
              { 'display': 'contents' }
            ]"
            class="pdf-content"
          >
            <div style="margin: 32px; display: flex; width: -webkit-fill-available;">
              <header-pdf title="Relatório de Campanha" />
            </div>
          </div>
          <v-col>
            <v-container :class="{ 'pdf-report': isPdf }">
              <v-row class="color-707780 justify-space-between">
                <v-col
                  :cols="isPdf ? 2 : 1"
                  :md="isPdf ? 2 : 1"
                  sm="2"
                  xs="2"
                >
                  <div class="title-first-card" :class="{ 'title-first-card-pdf': isPdf }">
                    <span class="font-weight-bold" :class="{ 'font-25': index == 0, 'font-18': index != 0 }">
                      {{ dashboardItem.title }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  :cols="isPdf ? 10 : 11"
                  :md="isPdf ? 10 : 11"
                  sm="10"
                  xs="10"
                >
                  <v-row>
                    <v-col
                      class="description-block border-right"
                      cols="12"
                      xs="12"
                      :sm="relatorioParcial ? 4 : 3"
                      :md="relatorioParcial ? 4 : 3"
                    >
                      <span class="title-value font-18">CARROS</span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.totalCarsAcquired || 0) }} </span>
                        contratados
                      </span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.totalCars || 0, 0) }} </span>
                        entregues
                      </span><br/>
                      <span class="description-percentage" :class="{
                          'text-success': dashboardItem.data.totalCarsPercent >= 100,
                          'text-info': dashboardItem.data.totalCarsPercent < 100,
                        }">
                        <i class="fas" :class="{
                          'fa-caret-up': dashboardItem.data.totalCarsPercent >= 100,
                        }"></i>
                        {{ formatNumber(dashboardItem.data.totalCarsPercent || 0) }}%
                      </span>
                    </v-col>

                    <v-col
                      class="description-block border-right"
                      cols="12"
                      xs="12" 
                      :sm="relatorioParcial ? 4 : 3"
                      :md="relatorioParcial ? 4 : 3"
                    >
                      <span class="title-value font-18">INSERÇÕES</span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.insertsAcquired || 0, 0, 0) }} </span>
                        contratadas
                      </span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.inserts || 0, 0) }} </span>
                        entregues
                      </span><br/>
                      <span class="description-percentage" :class="{
                          'text-success': dashboardItem.data.insertsPercent >= 100,
                          'text-info': dashboardItem.data.insertsPercent < 100,
                        }">
                        <i class="fas" :class="{
                          'fa-caret-up': dashboardItem.data.insertsPercent >= 100,
                        }"></i>
                        {{ formatNumber(dashboardItem.data.insertsPercent || 0) }}%
                      </span>
                    </v-col>

                    <v-col
                      class="description-block"
                      :class="{ 'border-right': !relatorioParcial}"
                      cols="12"
                      xs="12"
                      :sm="relatorioParcial ? 4 : 3"
                      :md="relatorioParcial ? 4 : 3"
                    >
                      <span class="title-value font-18">IMPACTOS</span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.impactAcquired || 0, 0) }} </span>
                        contratados
                      </span><br/>
                      <span class="font-16">
                        <span class="title-value">{{ formatNumber(dashboardItem.data.impact || 0, 0) }} </span>
                        entregues
                      </span><br/>
                      <span class="description-percentage" :class="{
                          'text-success': dashboardItem.data.impactPercent >= 100,
                          'text-info': dashboardItem.data.impactPercent < 100,
                        }">
                        <i class="fas" :class="{
                          'fa-caret-up': dashboardItem.data.impactPercent >= 100,
                        }"></i>
                        {{ formatNumber(dashboardItem.data.impactPercent || 0) }}%
                      </span>
                    </v-col>

                    <v-col
                      class="description-block"
                      cols="12"
                      xs="12"
                      sm="3"
                      md="3"
                      v-if="!relatorioParcial"
                    >
                      <span class="title-value font-18">CPM</span><br/>
                      <span class="font-16">
                        <span class="title-value">R$ {{ formatNumber(cpmDetail.cpmAcquired || 0) }} </span>
                        contratado
                      </span><br/>
                      <span class="font-16">
                        <span class="title-value">R$ {{ formatNumber(cpmDetail.cpm || 0) }} </span>
                        entregue
                      </span><br/>
                      <span class="description-percentage" :class="{
                          'text-danger': cpmDetail.cpmPercent > 100,
                          'text-info': cpmDetail.cpmPercent == 100,
                          'text-success': cpmDetail.cpmPercent < 100,
                        }">
                        <i class="fas" :class="{
                          'fa-caret-down': cpmDetail.cpmPercent > 100,
                          'fa-caret-up': cpmDetail.cpmPercent < 100,
                        }"></i>
                        {{ formatNumber(cpmDetail.cpmPercent || 0) }}%
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-if="showTopNeighborhoods && neighbordhoodsList.length && exibirPaginaInicial"
      :style="isPdf ? { 'margin': '45px' } : {}"
      class="fontNunitoSans"
    >
      <p class="break" v-if="isPdf && showDashboard && [2,6,10,14,18,22].includes(dashboardListSize)"></p>
      <div
        v-if="isPdf && showDashboard && [2,6,10,14,18,22].includes(dashboardListSize)"
        :style="[
          pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
          pdfMaxHeight ? { 'height': `0px` } : {},
          { 'display': 'contents' }
        ]"
        class="pdf-content"
      >
        <div style="margin: 32px; display: flex; width: -webkit-fill-available;">
          <header-pdf title="Relatório de Campanha" />
        </div>
      </div>
      <v-col>
        <v-container :class="{ 'pdf-report': isPdf }">
          <v-row class="align-stretch">
            <v-col cols="2">
              <div class="top-first-card ">
                <span class="font-36 font-weight-bold">TOP 5</span><br>
                <span class="font-16">Bairros</span>
              </div>
            </v-col>
            <v-col v-for="(top, index) in neighbordhoodsList" :key="index" class="align-stretch" cols="2">
              <div class="top-five-card align-stretch">
                <span class="font-36 font-weight-medium">{{ formatNumber(top.total || 0) }}%</span>
                <svg v-if="index < 3" class="star-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path :class="setColor(index)" d="M8.38947 1.5625L6.35822 5.71875L1.76447 6.375C0.951965 6.5 0.639465 7.5 1.23322 8.09375L4.51447 11.3125L3.73322 15.8438C3.60822 16.6562 4.48322 17.2812 5.20197 16.9062L9.29572 14.75L13.3582 16.9062C14.077 17.2812 14.952 16.6562 14.827 15.8438L14.0457 11.3125L17.327 8.09375C17.9207 7.5 17.6082 6.5 16.7957 6.375L12.2332 5.71875L10.1707 1.5625C9.82697 0.84375 8.76447 0.8125 8.38947 1.5625Z" fill="#F99C1C"/>
                </svg>
                <br>
                <span class="font-16">{{ top.neighborhood }}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <p class="break" v-show="isPdf && exibirPaginaInicial && (exibirGraficoInsercoes || exibirGraficoImpactos || exibirPaginaFinal)"></p>

    <v-container
      v-show="!isPdf && (showChart && ((seriesInsertion?.length && seriesInsertion[0].data?.length) || (seriesImpacts?.length && seriesImpacts[0].data?.length)))"
      class="fontNunitoSans">
      <button class="btn-tab" :class="{ 'active': showInsertions }" :disabled="showInsertions" @click="showInsertions = true, showImpacts = false">Inserções</button>
      <button class="btn-tab" :class="{ 'active': showImpacts }" :disabled="showImpacts" @click="showImpacts = true, showInsertions = false">Impactos</button>
      <v-row>
        <v-col cols="12">
          <apexchart
            v-show="showInsertions"
            ref="chartInsertions"
            width="100%"
            type="bar"
            :options="chartOptionsInsertions"
            :series="seriesInsertion"
          ></apexchart>

          <apexchart
            v-show="showImpacts"
            ref="chartImpacts"
            width="100%"
            type="bar"
            :options="chartOptions"
            :series="seriesImpacts"
          ></apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          * Média diária de acordo com o total de inserções/impactos contratados dividido pelo número de dias da campanha.
          <br/>
          <br/>
        </v-col>
      </v-row>
    </v-container>

    <div
      v-if="isPdf && exibirGraficoInsercoes"
      :style="[
        pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
        pdfMaxHeight ? { 'height': `0px` } : {},
      ]"
      class="pdf-content"
    >
      <header-pdf title="Relatório de Campanha" />
    </div>
    <div style="height: 150px;" v-show="isPdf && exibirGraficoInsercoes"></div>
  
    <div
      style="margin: 0 75px;"
      v-show="isPdf && exibirGraficoInsercoes && showChart && seriesInsertion?.length && seriesInsertion[0].data?.length"
      class="fontNunitoSans pdf-report"
    >
      <span class="title-value font-18">Inserções</span>
      <apexchart
        ref="chartInsertionsPDF"
        width="100%"
        type="bar"
        :options="chartOptionsInsertions"
        :series="seriesInsertion"
        style="min-height: 630px; min-width: 1150px;"
      ></apexchart>
    </div>

    <p class="break" v-show="isPdf && exibirGraficoInsercoes && (exibirGraficoImpactos || exibirPaginaFinal)"></p>

    <div
      v-if="isPdf && exibirGraficoImpactos"
      :style="[
        pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
        pdfMaxHeight ? { 'height': `0px` } : {},
      ]"
      class="pdf-content"
    >
      <header-pdf title="Relatório de Campanha" />
    </div>
    <div style="height: 150px;" v-show="isPdf && exibirGraficoImpactos"></div>

    <div
      style="margin: 0 75px;"
      v-show="isPdf && exibirGraficoImpactos && showChart && seriesImpacts?.length && seriesImpacts[0].data?.length"
      class="fontNunitoSans pdf-report"
    >
      <span class="title-value font-18">Impactos</span>
      <apexchart
        ref="chartImpactsPDF"
        width="100%"
        type="bar"
        :options="chartOptions"
        :series="seriesImpacts"
        style="min-height: 630px; min-width: 1150px;"
      ></apexchart>
    </div>

    <p class="break" v-if="isPdf && exibirGraficoImpactos && exibirPaginaFinal"></p>

    <div
      v-if="isPdf && exibirPaginaFinal"
      :style="[
        pdfMaxWidth ? { 'max-width': `${pdfMaxWidth}px` } : {},
        pdfMaxHeight ? { 'height': `${pdfMaxHeight}px` } : {},
      ]"
      class="pdf-content"
      >
      <header-pdf title="Relatório de Campanha - Mapa de Impacto" />
      <img
        v-if="pdfLoaded"
        :src="imagemFinal"
        :style="[
          pdfMaxWidth ? { 'max-width': '1240px' } : {},
          pdfMaxHeight ? { 'max-height': '960px' } : {},
        ]"
      />
    </div>
  </div>

  <Modal v-model="isShowUpload" :close="closeModalUpload">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Gerar relatório</h4>
          <button type="button" class="close" @click="closeModalUpload" aria-label="Close">
            <span span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <v-row>
            <v-col cols="12">
              <label for="file1">
                Imagem capa <sup class="text-danger">* </sup>:
                <div class="tooltip">
                  <span v-on="on" class="material-symbols-rounded font-16 cursor-pointer">
                    help
                  </span>
                  <span class="tooltiptext">
                    Altura: 730px
                    <br/>
                    Largura: 1240px
                  </span>
                </div>
              </label>
              <div class="upload-btn-wrapper" style="width: -webkit-fill-available">
                <div class="btn-group btn-group-documento">
                  <button class="btn btn-sm" :class="{ 'documento-ok': uploadFile.file1, 'documento-nok': !uploadFile.file1 }">
                    {{ uploadFile.file1 ? (uploadFile.file1 ? uploadFile.file1.name : '') : 'ANEXAR' }}
                    <input
                      type="file"
                      ref="file1"
                      @change="selectFile($event, 1)"
                      :accept="tiposArquivoAceito"
                    />
                  </button>

                  <button class="btn btn-sm btn-danger"
                    :style="['z-index: 1', uploadFile.file1 ? { marginLeft: '-7px' } : { display: 'none' }]"
                    @click="deleteFile(1)"
                  ><i class="fas fa-trash"></i></button>
                </div>
              </div>
              <span class="text-danger text-small">{{ uploadError.file1 }}</span>
            </v-col>
            <v-col cols="12">
              <label for="file2">
                Imagem página final <sup class="text-danger">* </sup>:
                <div class="tooltip">
                  <span v-on="on" class="material-symbols-rounded font-16 cursor-pointer">
                    help
                  </span>
                  <span class="tooltiptext">
                    Altura: 730px
                    <br/>
                    Largura: 1240px
                  </span>
                </div>
              </label>
              <div class="upload-btn-wrapper" style="width: -webkit-fill-available">
                <div class="btn-group btn-group-documento">
                  <button class="btn btn-sm" :class="{ 'documento-ok': uploadFile.file2, 'documento-nok': !uploadFile.file2 }">
                    {{ uploadFile.file2 ? (uploadFile.file2 ? uploadFile.file2.name : '') : 'ANEXAR' }}
                    <input
                      type="file"
                      ref="file2"
                      @change="selectFile($event, 2)"
                      :accept="tiposArquivoAceito"
                    />
                  </button>

                  <button class="btn btn-sm btn-danger"
                    :style="['z-index: 1', uploadFile.file2 ? { marginLeft: '-7px' } : { display: 'none' }]"
                    @click="deleteFile(2)"
                  ><i class="fas fa-trash"></i></button>
                </div>
              </div>
              <span class="text-danger text-small">{{ uploadError.file2 }}</span>
            </v-col>
            <v-col cols="12">
              <label for="file2">E-mail p/ envio automático: (separar por ',' caso seja mais de 1)</label>
              <br/>
              <input type="text" v-model="uploadEmail" class="form-control" />
              <span class="text-danger text-small">{{ uploadError.uploadEmail }}</span>
            </v-col>
            <v-col cols="6">
              <Toggle v-model="pages.exibir_capa" for="exibir_capa"/> <label for="exibir_capa">Capa &nbsp;&nbsp;</label>
            </v-col>
            <v-col cols="6">
              <Toggle v-model="pages.exibir_pagina_inicial" for="exibir_pagina_inicial" /> <label for="exibir_pagina_inicial">Página inicial &nbsp;&nbsp;</label>
            </v-col>
            <v-col cols="6">
              <Toggle v-model="pages.exibir_grafico_insercoes" for="exibir_grafico_insercoes" /> <label for="exibir_grafico_insercoes">Gráfico inserções &nbsp;&nbsp;</label> 
            </v-col>
            <v-col cols="6">
              <Toggle v-model="pages.exibir_grafico_impactos" for="exibir_grafico_impactos" /> <label for="exibir_grafico_impactos">Gráfico impactos &nbsp;&nbsp;</label>
            </v-col>
            <v-col cols="12">
              <Toggle v-model="pages.exibir_pagina_final" for="exibir_pagina_final" /> <label for="exibir_pagina_final">Página final &nbsp;&nbsp;</label> 
            </v-col>
            <span class="text-danger text-small text-center" style="width: -webkit-fill-available;">{{ uploadError.pages }}</span>
            <span class="text-danger text-small text-center" style="width: -webkit-fill-available;">{{ uploadError.upload }}</span>
            <span class="text-success text-small text-center" style="width: -webkit-fill-available; color: green !important; font-size: 18px; font-weight: bold;">
              {{ uploadSuccess }}
            </span>
          </v-row>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-outline-secondary" @click="closeModalUpload">Cancelar</button>
          <div class="btn-group">
            <button type="button" class="btn btn-success" @click="visualizarRelatorio">Pré-visualização</button>
            &nbsp;
            <button type="button" class="btn btn-success" @click="enviarRelatorio">Enviar</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>

  <div
    :class="{ 'showloader': showModalLoader }"
    class="modal fade text-center"
    tabindex="-4"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
  >
    <v-progress-circular
      indeterminate
      color="#343434"
      style="top: 50%; height: 50px; width: 50px;"
    ></v-progress-circular>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DateRangePicker from 'vue3-daterange-picker';
import Toggle from '@vueform/toggle';
import { Money3Directive } from 'v-money3';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import HeaderPDF from '../views/components/HeaderPDF.vue';
import FooterPDF from '../views/components/FooterPDF.vue';

export default defineComponent({
  name: 'HomeView',
  inheritAttrs: false,
  components: {
    DateRangePicker,
    Multiselect,
    Toggle,
    'header-pdf': HeaderPDF,
    'footer-pdf': FooterPDF,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(endDate.getDate() - 7);

    return {
      MAX_ATTACHMENT_SIZE: 5000000,
      showModalLoader: false,
      isShowUpload: false,
      tiposArquivoAceito: 'image/png,image/x-png,image/gif,image/jpeg,image/pjpeg',
      tiposArquivoAceitoTexto: 'Imagens (png, gif, jpeg)',
      uploadFile: {},
      uploadError: {},
      uploadSuccess: null,
      uploadEmail: null,
      showChart: false,
      relatorioParcial: false,
      exibirPorProduto: false,
      pages: {
        exibir_capa: true,
        exibir_pagina_inicial: true,
        exibir_grafico_insercoes: true,
        exibir_grafico_impactos: true,
        exibir_pagina_final: true,
      },
      seriesImpacts: [],
      seriesInsertion: [],
      chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString('pt-BR');
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"],
          },
        },
        xaxis: {
          position: 'bottom',
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "";
            },
          },
        },
      },
      chartOptionsInsertions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString('pt-BR');
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"],
          },
        },
        xaxis: {
          position: 'bottom',
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "";
            },
          }
        },
      },
      showInsertions: true,
      showImpacts: false,
      chart: {
        labels: [],
        datasets: [
          {
            label: 'Inserções contratadas',
            backgroundColor: '#90c99f',
            data: [],
          },
          {
            label: 'Inserções',
            backgroundColor: '#764B8E',
            data: [],
          },
        ],
      },
      chartReset: {
        labels: [],
        datasets: [
          {
            label: 'Inserções contratadas',
            backgroundColor: '#90c99f',
            data: [],
          },
          {
            label: 'Inserções',
            backgroundColor: '#764B8E',
            data: [],
          },
        ],
      },
      chartImpacts: {
        labels: [],
        datasets: [
          {
            label: 'Impactos contratados',
            backgroundColor: '#90c99f',
            data: [],
          },
          {
            label: 'Impactos',
            backgroundColor: '#764B8E',
            data: [],
          },
        ],
      },
      chartImpactsReset: {
        labels: [],
        datasets: [
          {
            label: 'Impactos contratados',
            backgroundColor: '#90c99f',
            data: [],
          },
          {
            label: 'Impactos',
            backgroundColor: '#764B8E',
            data: [],
          },
        ],
      },
      investment: 0,
      campaignNames: [],
      campaignsList: [],
      cliente: null,
      clienteList: [],
      agencia: [],
      agenciaListRaw: [],
      agenciaList: [],
      os: [],
      osList: [],
      produto: [],
      produtoList: [],
      cidade: [],
      cidadeList: [
        {
          value: 3106200,
          label: 'Belo Horizonte'
        },
        {
          value: 3550308,
          label: 'São Paulo'
        },
      ],
      erroMessage: null,
      erroCampanha: false,
      erroValidation: false,
      loading: 0,
      loadingFilter: false,
      showTopNeighborhoods: false,
      showDashboard: false,
      showPassengers: false,
      campanha: [],
      corridas: '',
      dateRange: {
        startDate,
        endDate,
      },
      dateRangeChanged: false,
      localeData:{
        format: 'dd/mm/yyyy',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        "daysOfWeek": [
          "Dom",
          "Seg",
          "Ter",
          "Qua",
          "Qui",
          "Sex",
          "Sáb",
        ],
        "monthNames": [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
      },
      campaigns: [],
      pdfData: {
        cliente: '',
        agencia: null,
        periodo: '',
        produto: '',
        pi: '',
      },
      pdfLoaded: false,
      imagemCapa: null,
      imagemFinal: null,
      pdfMaxWidth: null,
      pdfMaxHeight: null,
    }
  },
  directives: {
    money3: Money3Directive,
  },
  watch: {
    corridas: function(newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.corridas = result;
    },
    ordem: function(val) {
      this.ordem = val.replace(/[^0-9]/gi, '');
    },
  },
  mounted() {
    this.getClients();
  },
  computed: {
    isPdf() {
      return this.$store.state.pdf.isPdf;
    },
    exibirCapa() {
      return this.$store.state.pdf.exibirCapa;
    },
    exibirPaginaInicial() {
      return this.$store.state.pdf.exibirPaginaInicial;
    },
    exibirGraficoInsercoes() {
      return this.$store.state.pdf.exibirGraficoInsercoes;
    },
    exibirGraficoImpactos() {
      return this.$store.state.pdf.exibirGraficoImpactos;
    },
    exibirPaginaFinal() {
      return this.$store.state.pdf.exibirPaginaFinal;
    },
    agencyPDF() {
      return this.$store.state.pdf.agency;
    },
    piPDF() {
      return this.$store.state.pdf.pi;
    },
    filterPDF() {
      return this.$store.state.pdf.filter;
    },
    rangeDate() {
      let produtoList = [];
      let startDate = null;
      let endDate = null;

      this.campaignsList
        .filter((i) =>
          (!this.agencia?.length || this.agencia.includes(i.agencyId))
          && (!this.os?.length || this.os.includes(i.osNumber))
          && i.produtos?.length
        )
        .forEach((i) => {
          i.produtos
            .filter((p) => !this.produto?.length || this.produto.includes(p.cdCampanhaProduto))
            .forEach((p) => produtoList.push(p));
        });

      produtoList
        .filter((i) =>
          i.arquivos?.length
          && (!this.cidade?.length || i.arquivos.find((a) => this.cidade.includes(a.idCity)))
        )
        .forEach((i) => {
          let campanha = this.campaignsList.find((c) => c.cdCampanha == i.cdCampanha);

          startDate = !startDate || moment(campanha.startDate) < startDate ? moment(campanha.startDate) : startDate;
          endDate = !endDate || moment(campanha.endDate) > endDate ? moment(campanha.endDate) : endDate;
        });

      if (startDate) {
        return {
          startDate: startDate.format('DD/MM/YYYY'),
          endDate: endDate.format('DD/MM/YYYY'),
        };
      }

      return [];
    },
    show() {
      return (this.dashboardDetail.totalCars && this.cpmDetail && this.neighbordhoodsList.length);
    },
    configReal() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        disabled: false,
        masked: false, /* doesn't work with directive */
      };
    },
    validation() {
      return (this.cliente && this.produto.length && this.dateRange.startDate < new Date());
    },
    dashboardDetail() {
      const dashboard = this.$store.state.dashBoard.dashboard;

      return this.loadDashboardDetail(dashboard);
    },
    dashboardListSize() {
      return this.dashboardList?.length || 0;
    },
    dashboardList() {
      const products = this.$store.state.dashBoard.dashboard_products;
      const dashboardDetail = this.dashboardDetail;
      const dashboardProducts = [
        {
          title: 'Total',
          data: dashboardDetail,
        },
      ];

      if (this.exibirPorProduto) {
        products?.forEach(product => {
          dashboardProducts.push({
            title: this.produtoList.find(i => i.value == product.product_id)?.label,
            data: this.loadDashboardDetail(product.data),
          });
        });
      }

      return dashboardProducts;
    },
    passengers() {
      return this.$store.state.dashBoard.passengers;
    },
    cpmDetail() {
      const dashboard = this.$store.state.dashBoard.dashboard;
      const cpm = dashboard.cpm || 0;
      const cpmAcquired = dashboard.cpmAcquired || 0;
      const cpmPercent = cpm ? (cpmAcquired > 0 ? cpm * 100 / cpmAcquired : 0) : 0;

      return {
        cpm,
        cpmAcquired,
        cpmPercent,
      };
    },
    neighbordhoodsList() {
      return this.$store.state.dashBoard.neighbordhoods;
    },
    investimento() {
      let investimento = 0;
      let produtoList = [];

      this.campaignsList
        .filter((i) =>
          (!this.agencia?.length || this.agencia.includes(i.agencyId))
          && (!this.os?.length || this.os.includes(i.osNumber))
          && i.produtos?.length
        )
        .forEach((i) => {
          i.produtos
            .filter((p) => !this.produto?.length || this.produto.includes(p.cdCampanhaProduto))
            .forEach((p) => produtoList.push(p));
        });

      produtoList
        .filter((i) =>
          i.arquivos?.length
          && (!this.cidade?.length || i.arquivos.find((a) => this.cidade.includes(a.idCity)))
        )
        .forEach((i) => investimento += Number(i.piValue));

      let val = (investimento / 1).toFixed(2).replace('.', ',');
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    }
  },
  methods: {
    loadDashboardDetail(dashboard) {
      const totalCarsAcquired = this.onlyNumbers(dashboard.totalCarsAcquired);
      const insertsAcquired = this.onlyNumbers(dashboard.insertsAcquired);
      const impactAcquired = this.onlyNumbers(dashboard.impactAcquired);

      if (dashboard.totalCars) {
        dashboard.totalCarsPercent =
          (
            totalCarsAcquired > 0
            ? this.onlyNumbers(dashboard.totalCars) * 100 / totalCarsAcquired
            : 0
          );
      }

      if (dashboard.inserts) {
        dashboard.insertsPercent =
          (
            insertsAcquired > 0
            ? this.onlyNumbers(dashboard.inserts) * 100 / insertsAcquired
            : 0
          );
      }

      if (dashboard.impact) {
        dashboard.impactPercent =
          (
            impactAcquired > 0
            ? this.onlyNumbers(dashboard.impact) * 100 / impactAcquired
            : 0
          );
      }

      return dashboard;
    },
    validatePDF(stage) {
      if (this.isPdf) {
        this.dateRange = {
          startDate: moment(this.filterPDF.startDate).toDate(),
          endDate: moment(this.filterPDF.endDate).toDate(),
        };

        if (stage == 1) {
          this.imagemCapa = this.filterPDF.imagemCapa;
          this.imagemFinal = this.filterPDF.imagemFinal;
          this.cliente = this.clienteList?.find((i) => i.id == this.filterPDF.clientId);
          this.relatorioParcial = [true, 'true'].includes(this.filterPDF.relatorioParcial);
          this.exibirPorProduto = [true, 'true'].includes(this.filterPDF.exibirPorProduto);
          this.pdfMaxWidth = this.filterPDF.width;
          this.pdfMaxHeight = this.filterPDF.height;

          this.pdfData.cliente = this.cliente?.name;
          this.pdfData.periodo = `${moment(this.dateRange.startDate).format('DD/MM/YYYY')} à ${moment(this.dateRange.endDate).format('DD/MM/YYYY')}`;

          this.getClientCampaigns(this.filterPDF.clientId);

          this.produto = this.filterPDF.products?.split(',');
          this.pdfLoaded = true;
        } else if (stage == 2) {
          this.filtrar();
          this.getProductsSelected();
        }
      }
    },
    getProductsSelected() {
      const produtoList = new Set();
      const piNumberList = new Set();
      const agenciaList = new Set();

      this.campaignsList
        .filter((i) =>
          (!this.agencia?.length || this.agencia.includes(i.agencyId))
          && (!this.os?.length || this.os.includes(i.osNumber))
          && i.produtos?.length
        )
        .forEach((i) => {

          if (i.agencyId) {
            const agencia = this.agenciaListRaw.find((a) => i.agencyId == a.id);

            agencia && agenciaList.add(agencia.name);
          }

          i.produtos
            .filter((p) => !this.produto?.length || this.produto.includes(p.cdCampanhaProduto))
            .forEach((p) => {
              produtoList.add(p.productType);
              i.piNumber && piNumberList.add(i.piNumber);
            });
        });

      this.pdfData.produto = Array.from(produtoList).join(', ');
      this.pdfData.pi = this.piPDF || Array.from(piNumberList).join(', ');
      this.pdfData.agencia = this.agencyPDF || Array.from(agenciaList).join(', ');
      this.pdfData.periodo = `${moment(this.dateRange.startDate).format('DD/MM/YYYY')} à ${moment(this.dateRange.endDate).format('DD/MM/YYYY')}`;
    },
    getClients() {
      this.loadingFilter = true;
      this.erroMessage = null;

      this.$store
        .dispatch('dashBoard/getClient', { root: true })
        .then((response) => {
          this.clienteList = response.filter((i) => i.type == 1 && i.dataExists);
          this.agenciaListRaw = response.filter((i) => i.type != 1);
          this.loadingFilter = false;
          this.validatePDF(1);
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loadingFilter = false;
        });
    },
    getClientCampaigns(cliente) {
      this.loadingFilter = true;
      this.erroMessage = null;
      this.showChart = false;
      this.showTopNeighborhoods = false,
      this.showDashboard = false,
      this.showPassengers = false,
      this.campanha = [];
      this.campaignNames = [];
      this.campaignsList = [];
      this.agencia = [];
      this.os = [];
      this.produto = [];
      this.cidade = [];

      this.$store.commit('dashBoard/reset');

      this.$store
        .dispatch('dashBoard/getClientCampaigns', cliente, { root: true })
        .then((response) => {
          if (response?.length) {
            const agencyIds = response.map((i) => i.agencyId);

            this.campaignsList = response;
            this.agenciaList = this.agenciaListRaw
              .filter((i) => agencyIds.includes(i.id))
              .map((i) => ({
                value: i.id,
                label: String(i.name),
              }));

            this.loadingFilter = false;
            this.setOsList();
            this.validatePDF(2);
          }
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loadingFilter = false;
        });
    },
    setOsList() {
      this.loadingFilter = true;
      this.os = [];
      this.osList = this.campaignsList
        .filter((i) => !this.agencia?.length || this.agencia.includes(i.agencyId))
        .map((i) => ({
          value: i.osNumber,
          label: String(i.osNumber),
        }));

      this.setProductList();
      this.loadingFilter = false;
    },
    setDateRange() {
      this.dateRangeChanged = true
      this.setProductList()
    },
    setProductList() {
      this.loadingFilter = true;
      this.produto = null;
      let produtoList = [];

      if (!this.dateRangeChanged && this.rangeDate.startDate) {
        this.dateRange = {
          startDate: moment(this.rangeDate.startDate, 'DD/MM/YYYY').toDate(),
          endDate: moment(this.rangeDate.endDate, 'DD/MM/YYYY').toDate(),
        };
      }

      this.campaignsList
        .filter((i) =>
          (!this.agencia?.length || this.agencia.includes(i.agencyId))
          && (!this.os?.length || this.os.includes(i.osNumber))
          && i.produtos?.length
        )
        .forEach((i) => {
          i.produtos.forEach((p) => produtoList.push(p));
        });

      const dateRangeStart = moment(this.dateRange.startDate.toISOString().slice(0,10)).toDate();
      const dateRangeEnd = moment(this.dateRange.endDate.toISOString().slice(0,10)).toDate();

      produtoList = produtoList
        .filter((i) =>
          i.arquivos?.length
          && (!this.cidade?.length || i.arquivos.find((a) => this.cidade.includes(a.idCity)))
        );

      const produtoSelected = produtoList
        .filter((i) =>
          moment(i.startDate).isBetween(dateRangeStart, dateRangeEnd)
          || moment(i.startDate).isSame(dateRangeStart)
          || moment(i.startDate).isSame(dateRangeEnd)
          || moment(i.endDate).isBetween(dateRangeStart, dateRangeEnd)
          || moment(i.endDate).isSame(dateRangeStart)
          || moment(i.endDate).isSame(dateRangeEnd)
          || moment(dateRangeStart).isBetween(i.startDate, i.endDate)
          || moment(dateRangeStart).isSame(i.startDate)
          || moment(dateRangeStart).isSame(i.endDate)
          || moment(dateRangeEnd).isBetween(i.endDate, i.endDate)
          || moment(dateRangeEnd).isSame(i.startDate)
          || moment(dateRangeEnd).isSame(i.endDate)
        );

      this.produtoList = produtoList
        .map((i) => ({
          value: i.cdCampanhaProduto,
          label: i.descricao,
        }));

      const pdfProducts = this.filterPDF?.products ? this.filterPDF.products.split(',').map(i => parseInt(i)) : null;

      this.produto = produtoSelected
        .filter((i) => !pdfProducts || pdfProducts.includes(i.cdCampanhaProduto))
        .map((i) => i.cdCampanhaProduto);

      this.loadingFilter = false;
    },
    selectProduct() {
      if (this.rangeDate.startDate) {
        this.dateRange = {
          startDate: moment(this.rangeDate.startDate, 'DD/MM/YYYY').toDate(),
          endDate: moment(this.rangeDate.endDate, 'DD/MM/YYYY').toDate(),
        };
      }
    },
    setCampaignNames() {
      this.campaignNames = [];
      this.datasets = this.datasetsReset;
      this.investment = parseFloat(
        this.investimento
          .substr(3)
          .replace('.', '')
          .replace(',', '.')
      );
      let produtoList = [];

      this.campaignsList
        .filter((i) =>
          (!this.agencia?.length || this.agencia.includes(i.agencyId))
          && (!this.os?.length || this.os.includes(i.osNumber))
          && i.produtos?.length
        )
        .forEach((i) => {
          i.produtos
            .filter((p) => !this.produto?.length || this.produto.includes(p.cdCampanhaProduto))
            .forEach((p) => produtoList.push(p));
        });

      produtoList
        .filter((i) =>
          i.arquivos?.length
          && (!this.cidade?.length || i.arquivos.find((a) => this.cidade.includes(a.idCity)))
        )
        .forEach((i) => 
          i.arquivos.forEach((a) => this.campaignNames.push(a.midia))
        );
    },
    filtrar() {
      this.erroMessage = null;
      this.showChart = false;

      this.setCampaignNames();
      this.$store.commit('login/setToken', sessionStorage.getItem('token'));

      if (this.validation) {
        this.$store.commit('dashBoard/reset');
        // this.cpm();
        this.neighbordhoods();
        this.dashboard();
        this.getPassengers();
        this.getInsertions();
        this.getImpacts();
      } else {
        this.erroValidation = true;
      }
    },
    getImpacts() {
      this.loading++;
      this.$store
        .dispatch(
          'dashBoard/getImpacts',
          {
            clientId: this.cliente.id,
            campaignNames: this.campaignNames,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          },
          {
            root: true,
          },
        )
        .then((impacts) => {
          this.chartOptions.xaxis.categories = [];
          this.seriesImpacts = [{
            name: 'Impactos contratados',
            color: '#90c99f',
            data: [],
          }, {
            name: 'Impactos',
            color: '#6b6b6b',
            data: [],
          }];

          const arrDates = [];
          impacts.campaigns.forEach(e => {
            arrDates.push(moment(e.date).add(1, 'days').format('DD/MM/YYYY'));
            this.seriesImpacts[0].data.push(impacts.averageImpacts);
            this.seriesImpacts[1].data.push(e.impacts);
          });

          const categories = {
            xaxis: {
              categories: arrDates,
            }
          }

          this.chartOptions.xaxis.categories = arrDates;
          this.$refs.chartImpacts.updateOptions(categories);
          this.$refs.chartImpactsPDF.updateOptions(categories);

          this.showChart = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    getInsertions() {
      this.loading++;
      this.$store
        .dispatch(
          'dashBoard/getInsertions',
          {
            clientId: this.cliente.id,
            campaignNames: this.campaignNames,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          },
          {
            root: true,
          },
        )
        .then((insertions) => {
          this.chartOptionsInsertions.xaxis.categories = [];
          this.seriesInsertion = [{
            name: 'Inserções contratadas',
            color: '#95358b',
            data: [],
          }, {
            name: 'Inserções',
            color: '#6a2383',
            data: [],
          }];

          const arrDates = [];
          insertions.campaigns.forEach(e => {
            arrDates.push(moment(e.date).add(1, 'days').format('DD/MM/YYYY'));
            this.seriesInsertion[0].data.push(insertions.averageInsertions);
            this.seriesInsertion[1].data.push(e.inserts);
          });

          const categories = {
            xaxis: {
              categories: arrDates,
            }
          }

          this.chartOptionsInsertions.xaxis.categories = arrDates;
          this.$refs.chartInsertions.updateOptions(categories);
          this.$refs.chartInsertionsPDF.updateOptions(categories);

          this.showChart = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    cpm() {
      this.loading++;
      this.showCPM = false;
      this.$store
        .dispatch(
          'dashBoard/cpm',
          {
            campaignNames: this.campaignNames,
            investment: String(this.investment),
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          },
          {
            root: true,
          },
        )
        .then(() => {
          this.showCPM = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    neighbordhoods() {
      this.loading++;
      this.showTopNeighborhoods = false;
      this.$store
        .dispatch(
          'dashBoard/neighbordhoods',
          {
            campaignNames: this.campaignNames,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          },
          {
            root: true,
          },
        )
        .then(() => {
          this.showTopNeighborhoods = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    getPassengers() {
      this.loading++;
      this.showPassengers = false;
      this.$store
        .dispatch(
          'dashBoard/passengers',
          {
            campaignNames: this.campaignNames,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          },
          {
            root: true,
          },
        )
        .then(() => {
          this.showPassengers = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    dashboard() {
      this.loading++;
      this.showDashboard = false;
      this.$store
        .dispatch(
          'dashBoard/dashBoard',
          {
            clientId: this.cliente.id,
            campaignNames: this.campaignNames,
            products: this.produto,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
            investment: String(this.investment),
          },
          {
            root: true,
          },
        )
        .then(() => {
          this.showDashboard = true;
          this.loading--;
        })
        .catch((error) => {
          console.error(error?.response?.data || error);
          this.erroMessage = error?.response?.data?.message;
          this.erroCampanha = true;
          this.loading--;
        });
    },
    showModalEnviarRelatorio() {
      this.uploadFile = {};
      this.uploadError = {};
      this.uploadSuccess = null;
      this.isShowUpload = true;
      this.pages = {
        exibir_capa: true,
        exibir_pagina_inicial: true,
        exibir_grafico_insercoes: true,
        exibir_grafico_impactos: true,
        exibir_pagina_final: true,
      };
    },
    closeModalUpload() {
      this.isShowUpload = false;
    },
    selectFile(event, fileId) {
      this.uploadFile[`file${fileId}`] = undefined;
      this.uploadError[`file${fileId}`] = undefined;

      const file = event.target.files[0];

      if (file) {
        if (!this.tiposArquivoAceito.split(',').includes(file.type)) {
          this.uploadError[`file${fileId}`] = `Tipo de arquivo inválido, tipo aceitos são ${this.tiposArquivoAceitoTexto}.`;
        } else if (file && file.size > this.MAX_ATTACHMENT_SIZE) {
          this.uploadError[`file${fileId}`] = `O tamanho limite para cada documento é ${ this.MAX_ATTACHMENT_SIZE / 1000000 } MB.`;
        } else {
          this.uploadFile[`file${fileId}`] = file;
        }
      }
    },
    deleteFile(fileId) {
      this.uploadFile[`file${fileId}`] = undefined;
      this.$refs[`file${fileId}`].value = null;
    },
    validarRelatorio (envio = false) {
      this.uploadSuccess = null
      this.uploadError = {};

      let hasError = false;
      let pagesOk = false;

      if (this.pages.exibir_capa && !this.uploadFile.file1) {
        this.uploadError.file1 = 'A imagem deve ser selecionada.';
        hasError = true;
      }

      if (this.pages.exibir_pagina_final && !this.uploadFile.file2) {
        this.uploadError.file2 = 'A imagem deve ser selecionada.';
        hasError = true;
      }

      if (envio && !this.uploadEmail) {
        this.uploadError.uploadEmail = 'Informe ao menos um e-mail p/ envio.';
        hasError = true;
      }

      for (const page of Object.keys(this.pages)) {
        if (this.pages[page]) {
          pagesOk = true;
        }
      }

      if (!pagesOk) {
        this.uploadError.pages = 'Selecione ao menos uma página p/ gerar o relatório.';
      }

      return hasError || !pagesOk;
    },
    visualizarRelatorio () {
      if (!this.validarRelatorio()) {
        this.showModalLoader = true;
        this.setCampaignNames();
        this.getProductsSelected();

        const data = new FormData();
        data.append("files", this.uploadFile.file1);
        data.append("files", this.uploadFile.file2);
        data.append("exibir_capa", this.pages.exibir_capa);
        data.append("exibir_pagina_inicial", this.pages.exibir_pagina_inicial);
        data.append("exibir_grafico_insercoes", this.pages.exibir_grafico_insercoes);
        data.append("exibir_grafico_impactos", this.pages.exibir_grafico_impactos);
        data.append("exibir_pagina_final", this.pages.exibir_pagina_final);
        data.append("clientId", this.cliente.id);
        data.append("client", this.cliente?.name);
        data.append("agency", this.pdfData.agencia);
        data.append("product", this.pdfData.produto);
        data.append("pi", this.pdfData.pi);
        data.append("products", this.produto);
        data.append("campaignNames", this.campaignNames);
        data.append("startDate", moment(this.dateRange.startDate).toISOString());
        data.append("endDate", moment(this.dateRange.endDate).toISOString());
        data.append("investment", String(this.investment));
        data.append("relatorioParcial", this.relatorioParcial);
        data.append("exibirPorProduto", this.exibirPorProduto);
        data.append("hash", sessionStorage.getItem('token'));

        this.$store
          .dispatch('dashBoard/getPdfFromUrl', data)
          .then(() => {
            this.showModalLoader = false;
            this.uploadError = {};
            // this.closeModalUpload();
          })
          .catch(() => {
            this.showModalLoader = false;
            this.uploadError.upload = 'Erro ao enviar arquivos, tente novamente.';
          });
      }
    },
    enviarRelatorio() {
      if (!this.validarRelatorio(true)) {
        this.showModalLoader = true;
        this.setCampaignNames();
        this.getProductsSelected();

        const data = new FormData();
        data.append("files", this.uploadFile.file1);
        data.append("files", this.uploadFile.file2);
        data.append("uploadEmail", this.uploadEmail);
        data.append("exibir_capa", this.pages.exibir_capa);
        data.append("exibir_pagina_inicial", this.pages.exibir_pagina_inicial);
        data.append("exibir_grafico_insercoes", this.pages.exibir_grafico_insercoes);
        data.append("exibir_grafico_impactos", this.pages.exibir_grafico_impactos);
        data.append("exibir_pagina_final", this.pages.exibir_pagina_final);
        data.append("clientId", this.cliente.id);
        data.append("client", this.cliente?.name);
        data.append("agency", this.pdfData.agencia);
        data.append("period", this.pdfData.periodo);
        data.append("product", this.pdfData.produto);
        data.append("pi", this.pdfData.pi);
        data.append("products", this.produto);
        data.append("campaignNames", this.campaignNames);
        data.append("startDate", moment(this.dateRange.startDate).toISOString());
        data.append("endDate", moment(this.dateRange.endDate).toISOString());
        data.append("investment", String(this.investment));
        data.append("relatorioParcial", this.relatorioParcial);
        data.append("exibirPorProduto", this.exibirPorProduto);
        data.append("hash", sessionStorage.getItem('token'));

        this.$store
          .dispatch('dashBoard/sendPdfFromUrl', data)
          .then(() => {
            this.showModalLoader = false;
            this.uploadError = {};
            this.uploadSuccess = 'Relatório enviado.';
            // this.closeModalUpload();
          })
          .catch(() => {
            this.showModalLoader = false;
            this.uploadError.upload = 'Erro ao enviar arquivos, tente novamente.';
          });
      }
    },
    onlyNumbers(number) {
      return number.replace(/\D/g, '');
    },
    formatNumber(number, fractionDigits = 2) {
      return (number || 0).toLocaleString('pt-BR', {
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits,
        });
    },
    setColor(index) {
      if (index === 0) {
        return 'star-color-gold';
      } else if (index === 1) {
        return 'star-color-silver';
      } else if (index === 2) {
        return 'star-color-bronze';
      }
    },
  }
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
@page  {
  margin: 0;
}
.break {
  page-break-before: always;
}
.pdf-report {
  max-width: 100% !important;
}
.charts {
  height: 600px;
}
#bar-chart{
  height: min-content !important;
}
.btn-tab,
.active{
  padding: 8px 16px;

}
.active{
  background: #F0F4FF;
  border-radius: 48px;
  color: #7756C0;
  font-weight: 700;
}
.card-filtros{
  width: 100%;
  padding: 20px !important;
}
.v-chip{
  margin-bottom: 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.star-color-gold{
  fill: #F99C1C;
}
.star-color-silver{
  fill: #687D9B;
}
.star-color-bronze{
  fill: #EF4E23;
}
.star-icon{
  float: right;
}
.input-real{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C9CED4;
  border-radius: 4px;
  height: 40px;
}
.btn-filtrar {
  padding: 8px 16px;
  gap: 8px;
  width: 102px;
  height: 37px;
  color: white !important;
  background: #492E85;
  border-radius: 4px;
}
.loading{
  display: flex;
  align-items: center;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.v-field__append-inner{
  padding-top: 10px;
}
.v-field__field{
  padding-top: 10px;
}
.v-field__input{
  text-align: start;
  white-space: pre;
  overflow: hidden;
  align-self: baseline;
}
.title-value{
  font-weight: 700;
}
.v-input__details{
  display: none;
}
.v-input__control{
  background-color: white;
}
.col-periodo{
  display: grid;
}
.v-field{
  height: 40px;
}
.color-707780{
  color: #707780;
}
.subtitle{
  font-size: 12px;
}
.font-25{
  font-size: 25px;
}
.font-36{
  font-size: 2.25rem; //36px
}
.font-16{
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.description-block {
  text-align: center;
}
.border-right {
  border-right: 1px solid #707780!important;
}
.top-five-card{
  background: #FFFFFF;
  // border-radius: 4px;
  padding: 15px;
  height: 100%;
  border: 1px solid #EFF0F2;
  box-shadow: inset 4px 0px 0px #90c99f;
  border-radius: 3px;
}
.top-first-card {
  height: 132px;
  padding: 15px;
}
.title-first-card {
  width: 100%;
  word-wrap: break-word;
  display: inline-block;
  padding: 15px 0;
}
.title-first-card-pdf {
  padding-left: 60px !important;
}
@media (max-width: 600px) {
  .font-36{
    font-size: 1.5rem; //36px
  }
}
@media (min-width: 601px) {
}
.text-info {
  color: #3498db !important;
}
.text-success {
  color: #23a842 !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}
.center {
  margin: auto;
  width: fit-content;
}
.text-small {
  font-size: small;
}
.text-label {
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
  padding-right: 7px;
}

.pdf-content {
  flex: 1;
  padding: 20px;
  height: 1000px;
  margin-bottom: -50px;
  text-align: center;
}

.apexcharts-legend-marker {
  -webkit-print-color-adjust: exact;
}
</style>

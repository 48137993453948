<template>
    <v-container class="login-form">
        <v-row class="login-row">
            <v-col>
                <v-card class="text-center">
                    <v-card-title class="w-100 my-5">
                        <h1>Zanzar <span class="font-weight-regular">Dashboard</span></h1>
                    </v-card-title>
                    <v-card-text class="content">
                        <v-row>
                            <v-col>
                                <span>Informe login e senha para entrar</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="username"
                                    label="Login"
                                    class="login-field"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Password"
                                    @click:append="showPassword = !showPassword"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="error">
                            <v-col>
                                <span class="text-red-lighten-1">Dados inválidos! Tente novamente.</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="primary"
                                    @click="login()"
                                >
                                <span v-if="!loading">Entrar</span>
                                <span v-else>
                                    <v-progress-circular
                                        :size="20"
                                        :width="2"
                                        indeterminate
                                        color="lighten-5"
                                    ></v-progress-circular>
                                    Enviando
                                </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'LoginForm',
    data() {
        return {
            loading: false,
            username: '',
            password: '',
            showPassword: false,
            error: false,
        }
    },
    methods: {
        login() {
            this.loading = true
            this.$store.dispatch('login/login', {
                username: this.username,
                password: this.password,
                
            }, { root: true }).then(() => {
                this.loading = false

                this.$router.push('/');
            }).catch(() => {
                this.username = ''
                this.password = ''
                this.loading = false
                this.error = true;
            });
            // this.$router.push('/dashboard');
        },
    },
}
</script>
<style lang="scss">
    .login-form {
        height: 100vh;
        display: flex;
        align-items: center;
        max-width: 420px;
        .v-card{
            padding: 0.6rem;
            border-top: 3px solid #007bff;
        }
        .bg-primary {
            color: #fff;
            background-color: #007bff !important;
            border-color: #007bff;
            box-shadow: none;
        }
        .content{
            padding-top: 20px;
            border-top: 1px solid #e5e5e5;
        }
    }
</style>
<template>
  <div style="width: 100%; margin: 30px 0 60px 0; display: flex;">
    <div style="width: 70%; float: left; padding-left: 55px; text-align: left;">
      <h2 style="font-size: 34px; font-family: AvenirLT55Roman, sans-serif;">{{ title }}</h2>
    </div>
    <div style="width: 30%; float: right; padding-right: 55px;">
      <img
        style="width: 230px; float: right;"
        src="img/zanzar-peq.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPDF',
  props: ['title'],
}
</script>
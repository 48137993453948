const download = (fileContent, filename, contentType) => {
  const blob = new Blob([fileContent], { type: contentType });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFromUrl = (fileUrl, filename) => {
  const link = document.createElement('a');

  link.href = fileUrl;
  link.download = filename;
  link.target = '_blank';
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default {
  download,
  downloadFromUrl,
};

<template>
  <v-app>
    <v-app-bar app v-if="$route.path !== '/login' && !isPdf">
      <v-row>
        <v-col class="d-flex">
          <v-img
            max-height="32"
            max-width="124"
            :src="require('@/assets/zanzar-logo.png')"
          ></v-img>
          <h2 class="ml-5">Relatório Zanzar</h2>
        </v-col>
        <v-col align="right"> 
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                color="primary"
                icon
              >
               <v-avatar color="indigo">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :value="index"
                @click="sendFunction(item.title)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import axios from 'axios';

export default {
  name: 'App',
  props: ['token'],
  data: () => ({
    erroCampanha: false,
    idPdf: false,
    items: [
      { 
        title: 'Sair', 
        icon: 'mdi-pencil',
        click() {
          console.log('edit');
        }
      },
    ]
  }),
  mounted() {
    const hash = window.location.hash.startsWith('#/?') ? window.location.hash.substring(3) : window.location.hash;
    const params = [];
    hash.split('&').forEach(i => {
      const p = i.split('=');
      params[p[0]] = p[1];
    });

    const token = params.token || sessionStorage.getItem('token');

    if (token != undefined && token != null) {
      this.getToken(token, params.params);
    } else {
      this.$router.push('/login');
    }

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status !== 401) {
          this.erroCampanha = true;
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    isPdf() {
      return this.$store.state.pdf.isPdf;
    }
  },
  methods: {
    sendFunction(title) {
      if (title == 'Sair') {
        this.logout();
      }
    },
    logout() {
      sessionStorage.removeItem('token');
      this.$router.push('/login');
    },
    getToken(token, params) {
      this.$store.commit('login/setToken', token);

      if (params) {
        this.$store.commit('pdf/setFilter', params);
      }
      // this.$router.push('/');
    }
  }
}
</script>
<style lang="scss">
/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap'); */
  #app {
    font-family: 'AvenirLT55Roman', sans-serif;
  }
  .fontNunitoSans{
    font-family: 'Nunito Sans', sans-serif;

  }
  .fontNunito{
    font-family: 'Nunito', sans-serif;

  }
  .v-application{
    background-color: #FAFBFC;
  }

  .v-app-bar.v-toolbar:not(.v-toolbar--flat) {
    border-bottom: 1px solid #E6E9ED;
    box-shadow: none;
  }
</style>
